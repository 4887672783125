import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import '../src/css/style.css'
import { SpinnerBigX } from 'src/components/Spinner'
import { AlertProvider } from './contexts/AlertContext'
import Alert from './components/Alert'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const ForgetPassword = React.lazy(() => import('./views/forgetPassword/ForgetPassword'))
const RedefinePassword = React.lazy(() => import('./views/redefinePassword/redefinePassword'))

const App = () => {

  return (
    <BrowserRouter>
      <Suspense fallback={<SpinnerBigX />}>
        <AlertProvider>
            <Alert />
            <Routes>
              <Route
                path="/login"
                name="login"
                element={<Login />}
              />
              <Route path="/forgetPassword" name="ForgetPassword" element={<ForgetPassword />} />
              <Route
                path="/redefinepassword"
                name="RedefinePassword"
                element={<RedefinePassword />}
              />
              <Route
                path="*"
                name="Home"
                element={<DefaultLayout />}
              />
            </Routes>
        </AlertProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
